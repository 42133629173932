
    import {Component, Prop, Vue, Emit, Watch} from 'vue-property-decorator';
    //@ts-ignore
    import { modefyPassword,logout } from '@/api/public.js';
import user from '@/store/modules/user';


    @Component
    export default class HeadContainer extends Vue {
        @Prop({default: false}) private show!: boolean;
        @Prop({default: ''}) private title!: string;
        @Prop({default: []}) private crumb!: string;
        @Prop({default: ''}) private activePath!: string;
        private userInfo: object = {};
        private userRole:string;
        private mineTitle = this.title;
        private mineCrumb = this.crumb;
        private mineShow: boolean = this.show;
        private oldPass: string = '';
        private newPass: string = '';
        private checkNew: string = '';
        private showCover:boolean = false;
        private isShow:string = this.activePath;

        @Watch('show', {immediate: true, deep: true})
        private onShowChange(newValue: boolean, oldValue: boolean) {
            this.mineShow = newValue
        }

        @Watch('title', {immediate: true, deep: true})
        private onTitleChange(newValue: string, oldValue: string) {
            this.mineTitle = newValue
            // this.$forceUpdate()
        }

        @Watch('crumb', {immediate: true, deep: true})
        private onCrumbChange(newValue: any, oldValue: any) {
            this.mineCrumb = newValue;
        }
        // 监听当前路径，判断大屏按钮是否显示
        @Watch('activePath', {immediate: true, deep: true})
        private onactivePathChange(newValue: any, oldValue: any) {
            this.isShow = newValue;
        }

        private handleCrumbClick(name: string) {
            // if (this.$router.currentRoute.name === name) {
            //     return false
            // }
            this.emitCrumbActive(name)
        }

        @Emit('active')
        private emitCrumbActive(name: string) {
            return name
        }

        @Emit('logOut')
        private out(){
        }
        // 打开地图大屏
        private openMap(){
          this.$router.push('/largeScreen');
          this.isShow = '/largeScreen';
          // let token=sessionStorage.getItem('token');
          // let role=sessionStorage.getItem('roleId');
          // let url=`https://bctest.xinglinsou.cn/CommunityHtml/#/large?token=${token}&role=${role}`;
          // window.open(url,"_blank");
        }
        // 阻止冒泡
        private stop(e){
          window.event? window.event.cancelBubble = true : e.stopPropagation();
        }
        // 点击遮罩层关闭弹框
        private hideCover(){
          this.showCover=false;
        }
        // 确认修改密码
        private confirm(){
          let data={oldPassword:this.oldPass,newPassword:this.newPass};
          if(this.newPass===this.checkNew){
            modefyPassword({param:data}).then(res=>{
              if(res.success){
                this.$message({
                  showClose: true,
                  message: '修改密码成功，请退出当前帐号重新登陆',
                  type: 'success'
                });
                this.newPass='';
                this.oldPass='';
                this.checkNew='';
                this.showCover = false;
              }else{
                this.$message({
                  showClose: true,
                  message: res.Message,
                  type: 'error'
                });
              }
            })
          }else{
            this.$message({
              showClose: true,
              message: '两次输入的密码不一致',
              type: 'error'
            });
          }
        }
        // 取消修改密码
        private cancel(){
          this.showCover=false;
        }
        // private logOut(){}
        public handleCommand(command:any):void{
          if(command =='logOut'){//退出登录
            this.out();
          }else if(command==='rePass'){//修改密码
            this.showCover=true;
          }
        }
        public mounted(): void {
          let user=JSON.parse(sessionStorage.getItem('user'));
          this.userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {};
          this.$forceUpdate();
          this.userRole = this.$store.state.user.userData.role_name;
        }

    }
