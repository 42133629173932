<template>
  <section class="outContainer">
     <div class="menu">
      <el-button type="primary" @click="handleAdd(null)">新增</el-button>
     </div>
     <div class="tableContainer">
      <!-- 数据表格 -->
      <el-table
       :data="tableData"
       height="100%"
       style="width: 100%;"
       row-key="id"
       border
       :tree-props="{children: 'childrenList', hasChildren: 'hasChildren'}">
        <af-table-column align='center' label="菜单名称" prop="name" width='200'>
        </af-table-column>
        <af-table-column align='center' label="链接地址" prop="link">
        </af-table-column>
        <af-table-column align='center' label="是否冻结" prop="disabled" width='100'>
          <template slot-scope="scope">
            <p>{{ scope.row.disabled ? '是' : '否' }}</p>
          </template>
        </af-table-column>
        <el-table-column align='center' label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleAdd(scope.row,'edit')">修改</el-button>
            <el-button size="mini" @click="handleAdd(scope.row,'addChild')"  v-if="!scope.row.parentId">新增子菜单</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 新增菜单 -->
      <el-drawer
        :title="row && row.type != 'edit' ? '新增' : '编辑'"
        :visible.sync="addDrawer"
        :direction="direction"
        size="50%"
        :before-close="handleClose">
        <add v-if="addDrawer" @close='handleClose' ref='newForm' :row="row"></add>
      </el-drawer>
    </div>
  </section>
</template>
  
  <script>
  import {getTreeMenu} from '@/api/public.js';
  import add from './add.vue';
  
  export default {
    data() {
      return {
        tableData: [], // 表格数据
        addDrawer:false, // 控制新增弹框是否显示
        row:'',
        direction:'rtl',
        total:0,
      }
    },
  
    components: {add},
  
    computed: {},
  
    mounted() {
      this.init();
    },
    methods: {
      // 页面初始化
      async init(){
        await getTreeMenu().then(res=>{
          if(res.success){
            this.tableData = res.data;
          }
        })
      },
      // 打开新增、编辑菜单
      handleAdd(row,type){
        this.row = row;
        if(type){
          this.row.type = type
        }
        this.addDrawer = true;
      },
      // 关闭
      handleClose(){
        this.addDrawer = false;
        this.init()
      },
    },
  };
  </script>
  <style scoped>
    .tableContainer{
      overflow: auto;
    }
   .menu{
      width: 100%;
      height: 60px;
      width: 100%;
      padding: 10px 50px;
      position: relative;
    }
    .menu button{
      position: absolute;
      right:20px;
      top:10px;
    }
  </style>
  