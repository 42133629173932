<template>
  <el-menu
   :collapse="false" 
   collapse-transition router 
   :default-active="activePath"
   unique-opened
   class="el-menu-vertical-demo"
   background-color="#001529"
   text-color="#fff" 
   active-text-color="#ffd04b"
   @select="selectMenu">
    <div v-for="menu in allmenu" :key="menu.id">
      <el-submenu :index="menu.link" v-if="menu.childrenList.length > 0" class="nav-col">
        <template slot="title">
          <!-- <i :class="menu.icon"></i> -->
          <span>{{menu.name}}</span>
        </template>
        <el-menu-item-group>
          <el-menu-item v-for="chmenu in menu.childrenList" :index="chmenu.link" :key="chmenu.name" class="nav-col">
            <span>{{chmenu.name}}</span>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-menu-item :index="menu.link" class="nav-col" v-else>
        <!-- <i :class="menu.icon"></i> -->
        <span slot="title">{{menu.name}}</span>
      </el-menu-item>
    </div>
  </el-menu>
</template>
<script>
import { selectMenuByRoleId } from '@/api/public.js';
export default {
  name: 'leftnav',
  props:['isLogin'],
  data() {
    return {
      allmenu:[],
      user: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null,
      activePath:'',
      getMenu:sessionStorage.getItem('login'),
    }
  },
  // 创建完毕状态(里面是操作)
  created() {
  },
  watch:{
    isLogin: {
      deep: true,
      handler (val, oldVal) {
        this.user = JSON.parse(localStorage.getItem('userInfo'));
      }
    }
  },
  mounted(){
    this.getMenuList();
  },
  methods:{
    getMenuList(){
      this.user = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
      let id = this.user ? this.user.roleId : -1;
      this.allmenu = [
              {
                  "id": "d5bbd109b8134d0b94d940fde113c0ff",
                  "name": "小程序用户管理",
                  "link": "/appletUser",
                  childrenList:[],
              },
              {
                  "id": "5711816ccc3f438681c5f04b48fd098e",
                  "name": "检测记录",
                  "link": "/detectionRecords",
                  childrenList:[],
              },
              {
                  "id": "7c08c227b66549eaa03521eaaf0dd7c1",
                  "name": "柜机列表",
                  "link": "/cabinetList",
                  childrenList:[],
              },
              {
                  "id": "7fe030b2f52a474e89061b4e4af1a89b",
                  "name": "模板管理",
                  "link": "/template",
                  "childrenList": [
                      {
                          "id": "e91f3f9c570b45d194343661c4787756",
                          "name": "设备模板",
                          "link": "/template/equipTemplate",
                      },
                      {
                          "id": "e91f3f9c570b45d194343661c4787756",
                          "name": "动作模板",
                          "link": "/template/actionTemplate",
                      },
                      {
                          "id": "e91f3f9c570b45d194343661c4787756",
                          "name": "流水线模板",
                          "link": "/template/assemblyLineTemplate",
                      },
                  ],
                  "authority": null
              },
              {
                  "id": "78f0eedc53ea432dabd3156f97593027",
                  "name": "权限管理",
                  "link": "/rights",
                  "childrenList": [
                      {
                          "id": "279e029e541d4dc5b07c2cdb914959e7",
                          "name": "用户管理",
                          "link": "/rights/user",
                      },
                      {
                          "id": "c1bbbae7696243b9a19af0a2bfc46d92",
                          "name": "角色管理",
                          "link": "/rights/role",
                      },
                      {
                          "id": "01bb2eb8b27445fbb330dd903c716ffe",
                          "name": "菜单管理",
                          "link": "/rights/menu",
                      }
                  ],
                  "authority": null
              },
              {
                  "id": "d96c0c2a5e9448f38eb9d6ebe91a65bd",
                  "name": "客服问答列表",
                  "link": "/customer",
                  childrenList:[],
              },
              {
                  "id": "e91f3f9c570b45d194343661c4787755",
                  "name": "数据大屏",
                  "link": "/largeScreen",
                  childrenList:[],
              }
          ]
      this.$nextTick(async ()=>{
        await selectMenuByRoleId({param:id}).then(res => {
          if(res.success){
            this.allmenu = res.data;
            
          }
        })
            if(localStorage.getItem('activePath')){
              this.activePath = localStorage.getItem('activePath');
            }else{
              this.activePath = this.allmenu[0].childrenList.length > 0 ? this.allmenu[0].childrenList[0].link : this.allmenu[0].link;
              this.$router.push(this.activePath);
              localStorage.setItem('activePath',this.activePath);
            }
          })
      
    },
    // 菜单栏被激活回调
    selectMenu(e){
      localStorage.setItem('activePath',e)
      for(let i=0;i < this.allmenu.length;i++){
        if(e == this.allmenu[i].link){
          this.$emit('handleMenuActive',this.allmenu[i]);
          break;
        }else{
          let child = this.allmenu[i].childrenList;
          if(child.length > 0){
            for(let k=0;k<child.length;k++){
              if(e == child[k].link){
                this.$emit('handleMenuActive',this.allmenu[i],child[k]);
                break;
              }
            }
          }
        }
      }
    },
  }
}
</script>
<style lang="scss" scoped>
@import "@/static/default";
.nav-col:hover {
  background-color:rgba(24, 144, 255, 0.9) !important;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 100%;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  border: none;
  text-align: left;
}
.el-menu-item-group__title {
  padding: 0px;
}
.el-menu-bg {
  background-color: #1f2d3d !important;
}
.el-menu {
  border: none;
}
</style>