<template>
    <section>
      <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="100px" style="width:95%;overflow:auto">
        <el-form-item label="父级菜单名称" prop="name" v-if="row && row.type == 'addChild'">
          <p>{{ row.name }}</p>
        </el-form-item>
        <el-form-item label="菜单名称" prop="name">
          <el-input placeholder="请输入菜单名称" v-model="form.name">
          </el-input>
        </el-form-item>
        <el-form-item label="菜单路径" prop="link">
          <el-input placeholder="请输入菜单路径" v-model="form.link">
          </el-input>
        </el-form-item>
        <el-form-item label="权重" prop="weight">
          <el-input placeholder="请输入权重" v-model="form.weight">
          </el-input>
        </el-form-item>
        <el-form-item label="是否冻结" prop="disabled">
           <el-radio-group v-model="form.disabled">
             <el-radio-button :label="false">否</el-radio-button>
             <el-radio-button :label="true">是</el-radio-button>
           </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button @click="close('form')">取消</el-button>
          <el-button type="primary" @click="submit('form')" :plain='true'>立即提交</el-button>
        </el-form-item>
      </el-form>
    </section>
  </template>
  
  <script>
  import { createTreeMenu,updateTreeMenu } from '@/api/public.js';
  export default {
    props:['row'],
    data() {
      return {
        form:{
            parentId:null
        },
        imageUrl:'',
        value:[],
        options:[],
        show:true,
        rules: { // 表单验证
          name: [
            { required: true, message: "菜单名称不得为空", trigger: "blur" },
          ],
          link: [
            { required: true, message: "菜单路径不得为空", trigger: "blur" },
          ],
          weight: [
            { required: true, message: "菜单权重不得为空", trigger: "blur" },
          ],
          disabled: [
            { required: true, message: "请选择是否启用", trigger: "blur" },
          ],
        },
      };
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
      if(this.row && this.row.type == 'addChild'){
        this.form.parentId = this.row.id;
      }else{
        this.form = {...this.row}
      }
    },
  
    methods: {
       close(){
          this.$emit('close');
       },
       // 新增
       async add(){
        await createTreeMenu({param:this.form}).then((res) => {
          if (res.success) {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            setTimeout(() => {
              this.$emit('close');
            }, 1000);
          }else{
            this.$message({
              message: res.msg,
              type: "fail",
            });
          }
        })
        .catch(error => {});
       },
       // 编辑
       async edit(){
        await updateTreeMenu({param:this.form}).then((res) => {
          if (res.success) {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            setTimeout(() => {
              this.$emit('close');
            }, 1000);
          }else{
            this.$message({
              message: res.msg,
              type: "fail",
            });
          }
        })
        .catch(error => {});
       },
       // 提交
       submit(form) {
          this.$refs["form"].validate(valid => {
            if (valid) {
              if(this.row && this.row.type == 'edit'){
                this.edit()
              }else{
                this.add();
              }
            } else {
              return false;
            }
        });
      },
    }
  };
  </script>
 
 <style lang="scss" scoped>
   .el-drawer{
     overflow:auto !important;
   }
   .avatar-uploader .el-upload {
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      border: 1px dashed #ccc;
      border-radius: 50%;
      overflow:hidden;
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  </style>
  