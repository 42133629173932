<template>
    <section class="outContainer">
      <div class="role">
        <el-button type="primary" @click="handleAdd(null)">新增</el-button>
      </div>
      <!-- 表格 -->
      <div class="tableContainer">
        <el-table
         :data="roleData"
         height="100%"
         style="width: 100%;">
          <el-table-column align="center" label="角色名称" prop="name">
          </el-table-column>
          <el-table-column align="center" label="创建时间" prop="createTime">
          </el-table-column>
          <el-table-column align='center' label="操作" fixed="right">
            <template slot-scope="scope">
                <el-button size="mini" @click="handleAdd(scope.row)">编辑</el-button>
                <el-button size="mini" @click="handleRights(scope.$index, scope.row)">权限分配</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 新增 -->
        <el-drawer
          :title="row ? '编辑' : '新增'"
          :visible.sync="addDrawer"
          :direction="direction"
          :before-close="handleClose"
          size="50%">
          <add v-if="addDrawer" @close='handleClose' ref='newForm' :id="id" :row="row"></add>
        </el-drawer>
        <!-- 权限 -->
        <el-drawer
          title="权限设置"
          size="50%"
          :visible.sync="rightsDrawer"
          :direction="direction"
          :before-close="handleClose">
          <rights v-if="rightsDrawer" @close='handleClose' @success="handleDrawerSuccess(form)  "  ref='newForm' :id="id" :row="row"></rights>
        </el-drawer>
      </div>
    </section>
</template>

<script>
  import { getRoleList,deleteRole } from '@/api/public.js';
  import { timeChange } from '@/api/publicFun.js';
  import add from './add.vue';
  import rights from './rights.vue';
  export default {
    data() {
      return {
        roleData: [],
        addDrawer:false,
        rightsDrawer:false,
        id:'',
        row:'',
        direction:'rtl',
      }
    },
  
    components: {add,rights},
  
    computed: {},
  
    mounted() {
      this.init();
    },
  
    methods: {
      // 页面初始化
      async init(){
        await getRoleList({}).then(res=>{
          if(res.success){
            this.roleData = res.data;
            this.roleData.forEach((element) => {
              let eachTime = timeChange(element.createTime);
              element.createTime = `${eachTime.year}-${eachTime.month}-${eachTime.day}  ${eachTime.hour}:${eachTime.minutes}:${eachTime.seconds}`
            });
          }
        });
      },
      // 打开新增菜单
      handleAdd(row){
        this.row = row;
        this.addDrawer=true;
      },
      // 权限分配
      handleRights(index,row){
        this.rightsDrawer = true;
        this.row = row;
      },
      // 关闭
      handleClose(){
        this.row = null;
        this.addDrawer=false;
        this.rightsDrawer = false;
        this.init();
      },
      // 新增成功
      handleDrawerSuccess(){
        this.addDrawer=false;
        this.rightsDrawer = false;
        this.init();
      },
      // 删除菜单
      async handleDel(index,row){
          await this.$confirm('删除该角色后,用户列表中所有该角色的用户账号将都失效,确认删除吗？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              deleteRole({Id:row.Id}).then(res=>{
                if(res.Success){
                  this.$message({
                    message: "删除成功",
                    type: "success",
                  });
                  this.init();
                }
              })
            }).catch(() => {});
      },
    },
  };
</script>
<style scoped>
  .role{
    width: 100%;
    background-color: white;
    height: 60px;
    width: 100%;
    padding: 10px 50px;
    position: relative;
  }
  .role button{
    position: absolute;
    right:20px;
    top:10px;
  }
</style>
    